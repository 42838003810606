.form {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: stretch;
  margin: 1rem;
}

.textarea {
  display: block;
  width: 100%;
  border: none;
  font-size: 1rem;
  font-family: var(--type-first);
  resize: none;
  border: 1px solid #eee;
  padding: 0.5rem;
  border-radius: 0.2rem;
  background-color: #eee;
  transition: 0.2s;
}

.textarea::focus,
.textarea::hover {
  outline: none;
  border-color: #fb1;
  background-color: #ffffff;
  box-shadow: 0 0 0 3px #fea;
}

.button {
  border: none;
  cursor: pointer;
  color: #333333;
  background-color: transparent;
  font-size: 1rem;
  padding: 0 1rem;
  overflow: hidden;
}

.button:focus,
.button:hover {
  outline: none;
}

.button:focus svg path,
.button:hover svg path {
  fill: #fea;
  stroke: #fb1;
}

.button:focus svg g,
.button:hover svg g {
  animation: latir 0.6s infinite;
}

@keyframes latir {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
